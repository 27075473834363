import request from '@/core/services/api.service';

export function login(data) {
  return request({
    url: '/api/auth/login',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/api/me',
    method: 'get'
  })
}