import axios from 'axios';
import JwtService from "@/core/services/jwt.service";

// Create axios instance
const service = axios.create({
  baseURL: process.env.MIX_BASE_API,
  timeout: 100000, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
  config => {
    const token =  JwtService.getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + JwtService.getToken(); // Set JWT token
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  response => {
    if (response.headers.authorization) {
       JwtService.saveToken(response.headers.authorization);
      response.data.token = response.headers.authorization;
    }

    return response.data;
  },
  error => {   
    return Promise.reject(error);
  },
);

export default service;
